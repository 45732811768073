import React from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from '@mui/material/Box';

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector } from 'react-redux';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const DZDropdownMultiple = ({ value, onChange = () => { }, sx, error, data = [],label="User", fieldValueName = 'id', placeholder, fieldTextName = 'name', }) => {
value=value??[]
console.log(value,'value');
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const selectedValues = event.target.value;
        setPersonName(selectedValues);
        onChange(selectedValues);
      };
    return (

        <FormControl fullWidth sx={{ minWidth: 120 }}>
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={value}
                onChange={handleChange}
                // onChange={() => { handleChange(); onChange(); }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Div sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((item) => (
                            console.log("checking", item),
                            <Chip key={item?.[fieldValueName]} label={item?.[fieldTextName]} />
                        ))}
                    </Div>
                )}
                MenuProps={MenuProps}
            >
                {data?.map((item) => (
                    <MenuItem
                        key={item[fieldValueName]}
                        value={item}
                        style={getStyles(item[fieldTextName], personName, theme)}
                    >
                        <Checkbox checked={value?.indexOf(item) > -1} />
                        {item[fieldTextName]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

    );
};

export default DZDropdownMultiple;


export const DZDropdownMultipleNew = ({ value=[], onChange = () => { }, sx, error, data = [],style,  fieldValueName = 'id', placeholder="Users", fieldTextName = 'name', }) => {

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    console.log(value,"asdasdaa");
    return (

        <FormControl fullWidth sx={{ minWidth: 120 }}    style={style}>
            <InputLabel id="demo-multiple-chip-label">{placeholder}</InputLabel>
            <Select
         
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={value}
                onChange={onChange}
                // onChange={() => { handleChange(); onChange(); }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Div sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            console.log("checking", value),
                            <Chip key={value} label={value?.name} />
                        ))}
                    </Div>
                )}
                MenuProps={MenuProps}
            >
                {data?.map((item) => (
                
                    <MenuItem
                        key={item?.id}
                        value={item}
                        style={getStyles(item?.name, personName, theme)}
                    >
                    
                        <Checkbox checked={value.indexOf(item) > -1} />
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

    );
};


export const DZDropdownMultipleLanguage = ({
    value = [], // value prop should contain an array of selected items (sports)
    onChange = () => {},
    sx,
    error,
    data = [],
    style,
    fieldValueName = 'id',
    placeholder = 'Users',
    fieldTextName = 'name',
  }) => {
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const theme = useTheme();
  
    return (
      <FormControl fullWidth sx={{ minWidth: 120 }} style={style}>
        <InputLabel id="demo-multiple-chip-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={value}
          onChange={onChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Div sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value?.id} label={value?.name[selectedLanguage]} />
              ))}
            </Div>
          )}
          MenuProps={MenuProps}
        >
         {data.map((item) => (
          <MenuItem
            key={item.id}
            value={item}
            selected={value.some((selectedItem) => selectedItem.id === item.id)}
            style={{ fontWeight: value.some((selectedItem) => selectedItem.id === item.id) ? 'bold' : 'normal' }}
          >
            {item.name[selectedLanguage]}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    );
  };