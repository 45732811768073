import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { selectedChatIdSelector } from "app/rkt_query/SelectedStuffReducer";
import { useAddUserChatMessageMutation } from "app/rkt_query/storeApis";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import FileCard from "./FileCard";
import Div from "@jumbo/shared/Div";
import { Send, SendAndArchiveOutlined } from "@mui/icons-material";

const ActiveConversationFooter = () => {
  const selectedChatId = useSelector(selectedChatIdSelector);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [addMessageMutation] = useAddUserChatMessageMutation();
  const { fnShowSnackBar } = useSnackBarManager();
  const inputRef = useRef(null);

  const onSendMessage = async (event,triggeredBy=false) => {
    if ((triggeredBy === "button" || event.key === "Enter") && (message.trim() || files.length > 0)) {
      const formData = new FormData();
      formData.append("lead_user", selectedChatId);
      formData.append("message", message.trim());
      formData.append("is_mail", isEmail ? 1 : 0);
      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const res = await addMessageMutation(formData);
      if (res?.data?.message) {
        fnShowSnackBar(res?.data?.message || "Done successfully");
      } else {
        fnShowSnackBar(res?.error?.data?.message,"error");
      }
      setMessage("");
      setFiles([]);
      setIsEmail(false);
    }
  };

  const onFileChange = (event) => {
    setFiles([...event.target.files]);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleCheckboxChange = (event) => {
    setIsEmail(event.target.checked);
  };

  return (
    <Div
      sx={{
        display: "flex",
        alignItems: "start",
        p: (theme) => theme.spacing(2, 3),
        borderTop: 1,
        borderTopColor: "divider",
        bgcolor: (theme) => theme.palette.action.hover,
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", display: "flex", gap: "12px" }}>
        {files.map((file, index) => (
          <FileCard key={index} file={file} index={index} onDelete={removeFile} />
        ))}
      </div>
      <FormControlLabel
        control={
          <Checkbox checked={isEmail} onChange={handleCheckboxChange} color="primary" />
        }
        label="Send Email"
      />
      {/* <Div
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton color="primary" aria-label="attach file" component="label">
          <input type="file" hidden multiple onChange={onFileChange} />
          <AttachFileIcon />
        </IconButton>
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="small"
          inputRef={inputRef}
          placeholder="Type message...."
          onKeyDown={onSendMessage}
          fullWidth
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        />
      </Div> */}
      <div style={{ padding: '6px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #00000060', background: 'white', width: '100%' }}>
        <IconButton color="primary" aria-label="attach file" component="label">
          <input type="file" hidden multiple onChange={onFileChange} />
          <AttachFileIcon />
        </IconButton>
        {/* <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="small"
          inputRef={inputRef}
          placeholder="Type message...."
          onKeyDown={onSendMessage}
          fullWidth
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        /> */}
        <input type="text" value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onSendMessage}
          inputRef={inputRef}
           placeholder="Type Message..." style={{ width: '100%', border: '0', outline: 'none' }} />
        <IconButton color="primary" aria-label="attach file" component="label" onClick={(e) => onSendMessage(e, "button")} >
          <Send   />
        </IconButton>
      </div>
    </Div>
  );
};

export default ActiveConversationFooter;
