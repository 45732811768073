import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
  useGetAllLeadsQuery, BLUE_PRINTS, useGetLeadQuery, useDeleteLeadMutation,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Lead from './AddUpdateLead';
import { selectedUserLoginSelector, setSelectedLead } from 'app/rkt_query/SelectedStuffReducer';
import ViewLead from './ViewLead';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';
import { useJumboApp } from '@jumbo/hooks';
import { LAYOUT_NAMES } from 'app/layouts/layouts';

const LeadList = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteLead, responseDelete] = useDeleteLeadMutation();
  const { data: res_get_all, isLoading: isLoadingLeads, error: errorLeads } = useGetAllLeadsQuery();

  const [showMode, setShowMode] = useState(false);


  const { setActiveLayout } = useJumboApp();
  const selectedUserLogin = useSelector(selectedUserLoginSelector);
  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
      if (selectedUserLogin) {
          window.location.reload(false);
      }
  }, []);


  const bluePrint = BLUE_PRINTS.lead
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLead(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLead(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLead(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLead(row)); }
  bluePrint.handleDelete = (id) => { deleteLead(id); }

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
     
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Lead"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Lead"
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.leads}
              bluePrint={bluePrint}
              ViewModel={() => <ViewLead />}
              ModalContent={() => <Lead onClose={hideDialog} />}
              showAddButton={false}
              leadTitle="Lead Offers"
              showHideLeadTitle={false}
              hideViewBtn={false}
              showColorFullStatus={true}
              chatTitle="Chat"
              showHideChatUser={false}
            />

          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}

export default LeadList